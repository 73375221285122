<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">第三方WMS配置</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="success" @click="openDioalog(null, $t('c0246c55b9cac963'))" size="small"
						icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{ $t('hytxs0000060') }}</span>
					<cusSelFuzzy ref="cusSelFuzzy" :size="'small'" @changeData="changCus"></cusSelFuzzy>
				</li> -->
				<li>
					<span>{{ $t('c944a6686d996ab3') }}</span>
					<whNoSelect ref="whNoSelect" :size="'small'" @changeData="changWhNo"></whNoSelect>
				</li>
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable @keyup.enter.native="initData()"
						maxlength="50" :placeholder="$t('i18nn_3ee4c9b76289e93a')" style="width: 180px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.frameConHeightWh1" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="state" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.status"
							type="success">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag v-else-if="'90'===scope.row.status"
							type="warning">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag v-else type="info">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
				<!-- <el-table-column prop="cusName" :label="$t('hytxs0000060')"></el-table-column> -->

				<el-table-column prop="thridWhNo" :label="$t('i18nn_7a6f218c034d0ecd')"></el-table-column>

				<el-table-column prop="thridCode" :label="$t('i18nn_81c10442a4bd4247')"></el-table-column>

				<el-table-column prop="apiUrl" :label="'apiUrl'"></el-table-column>
				<el-table-column prop="clientKey" :label="'clientKey'"></el-table-column>
				<el-table-column prop="clientSecret" :label="'clientSecret'"></el-table-column>

				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="260px" fixed="right">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 5px;">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>

								<el-button v-if="'10'==scope.row.status" @click="closeAction($event, scope.row)" type="warning"
									size="mini" icon="el-icon-close">{{$t('4e9fc68608c60999')}}</el-button>

								<el-button v-else @click="openAction($event, scope.row)" type="success" size="mini"
									icon="el-icon-check">{{$t('70fdf36bdc2b0f58')}}</el-button>
							</div>

						</div>
					</template>
				</el-table-column> -->
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!--新增-->
		<!-- <ThirdWmsConfigAdd :openTime="whAddOpenTime" :row="whRow" @success="AddSuccessBack"></ThirdWmsConfigAdd> -->

	</div>
</template>
<script>
	// import ThirdWmsConfigAdd from '@/components/WarehouseCenter2/ThirdWmsConfig/ThirdWmsConfigAdd.vue';

	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';

	export default {
		components: {
			// cusSelFuzzy,
			whNoSelect,
			// ThirdWmsConfigAdd
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false
		// 		},
		// 		type: Boolean
		// 	},
		// },
		data() {
			return {
				// whAddOpenTime: '',
				// whRow: {},

				loading: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {

				},
				//查询，排序方式
				filterData: {
					orderBy: 'sort_no', //排序字段
					sortAsc: 'N', //desc降序，asc升序
					// userName: '',
					userId: '',
					whNo: '',
					keyword: ''
				}
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.getPageData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				this.initData();
			},

			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	this.whRow = formParm;
			// 	this.whAddOpenTime = new Date().getTime();

			// },
			// //打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },
			// AddSuccessBack() {
			// 	this.initData();
			// },

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhThirdWmsConfigDel + '/' + row.id, {}, 'delete');
			// 		})
			// 		.catch(() => {});
			// },
			// //开启
			// openAction(event, row) {
			// 	this.postData(this.$urlConfig.WhThirdWmsConfigOpenClose, {
			// 		id: row.id
			// 	}, '');
			// },
			// //关闭
			// closeAction(event, row) {
			// 	this.postData(this.$urlConfig.WhThirdWmsConfigOpenClose, {
			// 		id: row.id
			// 	}, '');
			// },

			//提交信息
			postData(url, formData, type) {
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.getPageData();
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhThirdWmsConfigPageList, {
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						userId: this.filterData.userId ? this.filterData.userId : null,
						whNo: this.filterData.whNo ? this.filterData.whNo : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null
					})
					.then(({
						data
					}) => {
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// @import url(@/assets/css/client_module.less);
</style>